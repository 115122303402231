import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  //useTheme,
  //useMediaQuery,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Button,
  IconButton,
  Select,
  Menu,
  MenuItem,
  //Divider,
  Chip,
  useTheme,
  useMediaQuery,
  Box,
  Autocomplete,
  TextField
} from '@mui/material';
import {
  PersonAddAltOutlined,
  PeopleOutline,
  KeyboardArrowDown,
  LinkOutlined,
  PersonOutlineOutlined,
  Clear,
  Public,
  SettingsOutlined,
  Close,
  LockOutlined
} from '@mui/icons-material';
import { debounce } from '@mui/material/utils';

import { showSnackbar } from 'features/Snackbar';
import {
  accessType,
  accessTypeLabels,
  entityTypes,
  linkLifeTimeType
} from 'shared/constants';
import { selectViewer } from 'entities/auth';
import { getUserInfo } from 'entities/user';
import { fetchShareInfo } from 'entities/share';
import { list as getRoleList } from 'entities/role';
import {
  fetchDirectoryThunk,
  unshareDirectoryThunk
} from 'entities/documentDirectory';
import { unshareDocumentThunk } from 'entities/documents';
import {
  generateLink,
  editLink,
  getLinksByIds,
  deleteLink
} from 'entities/link';
import { SearchUserDialog } from '../SearchUser';
import { rootDirectoriesConfig } from 'entities/documentDirectory/config';
import { ExternalLink } from '../ExternalLink';
import { CopyStringToClipboard } from '../../shared/lib/clipboard';

export const ShareDialog = ({ entity, onClose, onSuccess }) => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const mediaQuery = theme.breakpoints.down('sm');

  const isMobile = useMediaQuery(mediaQuery);

  const viewer = useSelector(selectViewer);
  const [searchUserDialogOpen, setSearchUserDialogOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [roleList, setRoleList] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [usersAccessTypes, setUsersAccessTypes] = React.useState([]);
  const [roleListAnchor, setRoleListAnchor] = React.useState(null);

  const [rolesCustomList, setRolesCustomList] = React.useState([]);

  const [externalLinks, setExternalLinks] = React.useState([]);

  const [externalLinkData, setExternalLinkData] = React.useState(null);

  // const [sendNotificationChecked, setSendNotificationChecked] =
  //   React.useState(false);
  // const [notificationText, setNotificationText] = React.useState('');
  const handleToggleRoleListAnchor = (event) =>
    setRoleListAnchor(!event ? null : event.currentTarget);

  const selectOptions = [
    { name: 'Чтение', value: accessType.Read },
    { name: 'Рецензирование', value: accessType.Review },
    { name: 'Комментирование', value: accessType.Comment },
    { name: 'Запись', value: accessType.Write },
    {
      name: 'Только загрузка',
      value: accessType.OnlyUpload,
      disabled: !entity.IsFolder
    },
    { name: 'Полный доступ', value: accessType.Full },
    { name: 'Запрещён', value: accessType.Deny, disabled: true }
  ];

  const handleSelectRole = (role) => {
    if (roles.find((x) => x.type === 0 && x.Id === role.Id)) {
      dispatch(
        showSnackbar({
          type: 'info',
          message: 'Данной роли уже установлены права'
        })
      );
    } else {
      setRoles((state) => state.concat({ ...role, type: 0 }));
      setUsersAccessTypes((state) => [
        ...state,
        {
          roleId: role.Id,
          value: selectOptions[0].value,
          type: 0
        }
      ]);
      setRolesCustomList([]);
    }
    handleToggleRoleListAnchor();
  };

  const handleRemoveShare = async ({ type, id }) => {
    const handler = entity.IsFolder
      ? unshareDirectoryThunk
      : unshareDocumentThunk;

    const payload = {
      AccessType: accessType.Deny,
      Ids: [entity.Id]
    };

    if (type === 'role') {
      payload.ToRoleIds = [id];
      setRoles((state) =>
        state.filter((role) => role.Id !== id || role.type === 1)
      );
      setUsersAccessTypes((state) =>
        state.filter((access) => access.roleId !== id || access.type === 1)
      );
    } else {
      payload.ToUserIds = [id];
      setUsers((state) =>
        state.filter((user) => user.Id !== id || user.type === 1)
      );
      setUsersAccessTypes((state) =>
        state.filter((access) => access.userId !== id || access.type === 1)
      );
    }

    dispatch(handler(payload));
    dispatch(fetchDirectoryThunk());
  };

  const fetchExternalLinks = React.useCallback(
    async (sharedLinks) => {
      if (sharedLinks) {
        setExternalLinks(sharedLinks);
        return;
      }

      const data = await getLinksByIds({
        EntityType: entity.IsFolder
          ? entityTypes.Directory
          : entityTypes.Document,
        Ids: [entity.Id]
      });

      setExternalLinks(data);
    },
    [entity]
  );

  const init = React.useCallback(async () => {
    if (!entity) return;

    const data = await dispatch(
      fetchShareInfo({
        entityId: entity.Id,
        entityType: entity.IsFolder ? 'Directory' : 'Document'
      })
    ).unwrap();

    await fetchExternalLinks(data.SharedLinks);

    const users = data.UserAccessRights.map((x) => ({
      Id: x.PrincipalId,
      access: x.Type,
      type: 0
    }));

    const parentUsers =
      data.Parent.UserAccessRights?.map((x) => ({
        Id: x.PrincipalId,
        access: x.Type,
        type: 1
      })) || [];

    const allUsers = [...parentUsers, ...users];
    const usersInfo = await getUserInfo([
      ...new Set(allUsers.map((x) => x.Id))
    ]);

    allUsers.forEach((user) => {
      const userInfo = usersInfo.find((x) => x.Id === user.Id);

      if (userInfo) {
        user.FirstName = userInfo.FirstName;
        user.Surname = userInfo.Surname;
        user.Email = userInfo.Email;
      }
    });

    const roleList = await getRoleList();

    const roles = data.RoleAccessRights.map((x) => ({
      Id: x.PrincipalId,
      access: x.Type,
      type: 0
    }));

    const parentRoles =
      data.Parent.RoleAccessRights?.map((x) => ({
        Id: x.PrincipalId,
        access: x.Type,
        type: 1
      })) || [];

    const allRoles = [...parentRoles, ...roles];

    setRoleList(roleList);
    setUsers(allUsers);
    setRoles(
      allRoles.map((role) => {
        const found = roleList.find((item) => item.Id === role.Id);
        return { Id: found.Id, Name: found.Name, type: role.type };
      })
    );
    const fullList = [
      ...allUsers.map((x) => ({
        userId: x.Id,
        value: x.access,
        type: x.type,
        roleId: null
      })),
      ...allRoles.map((x) => ({
        roleId: x.Id,
        value: x.access,
        type: x.type,
        userId: null
      }))
    ];
    setUsersAccessTypes(fullList);
  }, [dispatch, entity, fetchExternalLinks]);

  React.useEffect(() => {
    init();
  }, [init]);

  const handleAccessChange = (id, isRole) => (event) => {
    setUsersAccessTypes((state) =>
      state.map((item) => {
        if (isRole) {
          return item.roleId !== id || item.type === 1
            ? item
            : { roleId: id, value: event.target.value, type: 0 };
        }

        return item.userId !== id || item.type === 1
          ? item
          : { userId: id, value: event.target.value, type: 0 };
      })
    );
  };

  const handleSuccess = () => {
    const payload = selectOptions.reduce((result, option) => {
      const AccessType = option.value;
      const ToUserIds = usersAccessTypes
        .filter(
          (item) => item.userId && item.value === AccessType && item.type === 0
        )
        .map((item) => item.userId);
      const ToRoleIds = usersAccessTypes
        .filter(
          (item) => item.roleId && item.value === AccessType && item.type === 0
        )
        .map((item) => item.roleId);

      if (!ToUserIds.length && !ToRoleIds.length) return result;

      return result.concat({
        AccessType,
        ToUserIds,
        ToRoleIds,
        Ids: [entity.Id]
      });
    }, []);

    onSuccess(payload);
  };

  const handleCopyOuterLink = async (item) => {
    if (!entity.IsFolder) {
      CopyStringToClipboard(
        `${window.location.origin}/doc.html?uid=${
          item.Uid + '_' + item.Version
        }`
      );
    } else {
      CopyStringToClipboard(`${window.location.origin}/link/${item.Uid}`);
    }

    dispatch(
      showSnackbar({
        type: 'info',
        message: 'Ссылка для внешнего доступа скопирована'
      })
    );
  };

  const handleExternalLinkSubmit = async (values) => {
    const type = entity.IsFolder ? entityTypes.Directory : entityTypes.Document;

    if (externalLinkData?.Uid) {
      await editLink({
        Id: values.id,
        Type: type,
        AccessType: values.accessType,
        LifeTime: linkLifeTimeType.Unlimited,
        ExpirationDate: values.expirationDate,
        Password: values.password
      });
    } else {
      await generateLink({
        ...values,
        type,
        lifeTime: linkLifeTimeType.Unlimited
      });
    }

    setExternalLinkData(null);
    fetchExternalLinks();
  };

  const handleCopyInnerLink = () => {
    CopyStringToClipboard(
      window.location.origin +
        (entity.IsFolder
          ? `${rootDirectoriesConfig.sharedToMe.path}/${entity.Id}`
          : `/doc.html?id=${entity.Id}`)
    );

    dispatch(
      showSnackbar({
        type: 'info',
        message: 'Ссылка для внутреннего доступа скопирована'
      })
    );
  };

  const handleDeleteLink = async (uid) => {
    await deleteLink(uid);
    setExternalLinks((state) => state.filter((item) => item.Uid !== uid));
  };

  const changeRoleFieldText = (v) => {
    setRolesCustomList(
      roleList.filter(
        (role) =>
          !roles.find(
            (item) => item.Id === role.Id && item.type === role.type
          ) && role.Name.toLowerCase().includes(v.toLowerCase())
      )
    );
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        onClose={onClose}
        PaperProps={{ sx: !isMobile ? null : { width: '100%', margin: 1 } }}
      >
        <DialogTitle
          variant="h5"
          sx={{
            textAlign: 'center',
            wordBreak: 'break-all',
            padding: isMobile ? 1.5 : ''
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack sx={{ width: 'calc(100% - 40px)' }}>
              Доступ - {entity?.Name}
            </Stack>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Stack
              direction={isMobile ? 'column' : 'row'}
              alignItems="center"
              justifyContent="space-between"
              spacing={isMobile ? 1 : 0}
            >
              <Typography sx={{ fontSize: '1.125rem' }}>
                Пользователи портала
              </Typography>
              <Stack direction="row" spacing={1}>
                <StyledButton
                  startIcon={<PersonAddAltOutlined />}
                  onClick={() => setSearchUserDialogOpen(true)}
                >
                  Добавить
                </StyledButton>
                <StyledButton
                  startIcon={<LinkOutlined />}
                  onClick={handleCopyInnerLink} //onCopyInnerLink
                >
                  Копировать
                </StyledButton>
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 0.5 }}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <PersonOutlineOutlined />
                  <Typography>{entity?.UserName}</Typography>
                </Stack>
                <Typography sx={{ color: 'text.secondary' }}>
                  Владелец
                </Typography>
              </Stack>
              {users.map((item) => (
                <Stack
                  key={`${item.Id}_${item.type}`}
                  direction={isMobile ? 'column' : 'row'}
                  alignItems={isMobile ? 'flex-start' : 'center'}
                  justifyContent="space-between"
                  spacing={isMobile ? 0.5 : 0}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ width: `calc(100% - ${isMobile ? 0 : 190}px)` }}
                  >
                    <PersonOutlineOutlined />
                    <Typography
                      noWrap
                      sx={{ ml: 0.5, mr: 'auto', width: 'calc(100% - 64px)' }}
                    >
                      {!!item.FirstName || !!item.Surname
                        ? `${item.FirstName} ${item.Surname}`
                        : item.Email}
                    </Typography>
                    {item.type == 0 && (
                      <Clear
                        onClick={() => handleRemoveShare({ id: item.Id })}
                        aria-labelledby="Удалить"
                        style={{ fill: '#ff6a6a', cursor: 'pointer' }}
                      />
                    )}
                  </Stack>
                  {item.type == 0 ? (
                    <Select
                      variant="standard"
                      value={
                        usersAccessTypes.find(
                          (accessType) =>
                            accessType.userId === item.Id &&
                            accessType.type === item.type
                        )?.value
                      }
                      onChange={handleAccessChange(item.Id)}
                      sx={{ width: isMobile ? '100%' : 180 }}
                    >
                      {selectOptions.map((option) => (
                        <MenuItem
                          disabled={!!option.disabled}
                          key={option.value}
                          value={option.value}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Box sx={{ width: 180, color: 'grey!important' }}>
                      {
                        selectOptions.find(
                          (x) =>
                            x.value ===
                            usersAccessTypes.find(
                              (accessType) =>
                                accessType.userId === item.Id &&
                                accessType.type === item.type
                            )?.value
                        )?.name
                      }
                    </Box>
                  )}
                </Stack>
              ))}
            </Stack>
            <StyledButton
              startIcon={<PeopleOutline />}
              endIcon={<KeyboardArrowDown />}
              onClick={handleToggleRoleListAnchor}
              sx={{ alignSelf: isMobile ? 'center' : 'baseline' }}
            >
              Добавить роль
            </StyledButton>
            {!!roles.length && (
              <Stack spacing={1}>
                {roles.map((role) => (
                  <Stack
                    key={`${role.Id}_${role.type}`}
                    direction={isMobile ? 'column' : 'row'}
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    justifyContent="space-between"
                    spacing={isMobile ? 0.5 : 0}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ width: `calc(100% - ${isMobile ? 0 : 190}px)` }}
                    >
                      <PersonOutlineOutlined />
                      <Typography
                        noWrap
                        sx={{ ml: 0.5, mr: 'auto', width: 'calc(100% - 64px)' }}
                      >
                        {role.Name}
                      </Typography>
                      {role.type === 0 && (
                        <Clear
                          onClick={() =>
                            handleRemoveShare({ id: role.Id, type: 'role' })
                          }
                          aria-labelledby="Удалить"
                          style={{ fill: '#ff6a6a', cursor: 'pointer' }}
                        />
                      )}
                    </Stack>
                    {role.type === 0 ? (
                      <Select
                        variant="standard"
                        value={
                          usersAccessTypes.find(
                            (accessType) =>
                              accessType.roleId === role.Id &&
                              accessType.type === role.type
                          ).value
                        }
                        onChange={handleAccessChange(role.Id, true)}
                        sx={{ width: isMobile ? '100%' : 180 }}
                      >
                        {selectOptions.map((option) => (
                          <MenuItem
                            disabled={!!option.disabled}
                            key={option.value}
                            value={option.value}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Box sx={{ width: 180, color: 'grey!important' }}>
                        {
                          selectOptions.find(
                            (x) =>
                              x.value ===
                              usersAccessTypes.find(
                                (accessType) =>
                                  accessType.roleId === role.Id &&
                                  accessType.type === role.type
                              ).value
                          ).name
                        }
                      </Box>
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
            <Menu
              open={!!roleListAnchor}
              anchorEl={roleListAnchor}
              onClose={() => handleToggleRoleListAnchor()}
              sx={{ maxHeight: 300 }}
            >
              <Autocomplete
                freeSolo
                disableClearable={false}
                options={rolesCustomList}
                onInputChange={(_, v) => debounce(changeRoleFieldText(v), 500)}
                getOptionLabel={(option) => option.Name}
                onChange={(_, value) => handleSelectRole(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    size='small'
                    sx={{ width: 300, padding: 1 }}
                    //label={'Роль'}
                  />
                )}
                filterOptions={(x) => x}
              />

              {/* {roleList
                .filter(
                  (role) =>
                    !roles.find(
                      (item) => item.Id === role.Id && item.type === role.type
                    )
                )
                .map((role) => (
                  <MenuItem
                    key={`${role.Id}_${role.type}`}
                    onClick={handleSelectRole(role)}
                  >
                    {role.Name}
                  </MenuItem>
                ))} */}
            </Menu>
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontSize: '1.125rem' }}>
                  Внешний доступ
                </Typography>
                <StyledButton
                  startIcon={<Public />}
                  onClick={() =>
                    setExternalLinkData({
                      Id: entity.Id,
                      EntityType: entity.IsFolder
                        ? entityTypes.Directory
                        : entityTypes.Document
                    })
                  }
                >
                  Создать внешнюю ссылку
                </StyledButton>
              </Stack>
            </Stack>
            <Stack
              sx={{
                maxHeight: 300,
                overflowY: 'auto',
                pr: '10px',
                marginRight: '-10px !important'
              }}
            >
              {externalLinks.map((item, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center">
                    {!isMobile && (
                      <Typography sx={{ mr: 1 }}>Внешняя ссылка</Typography>
                    )}
                    <IconButton
                      size={isMobile ? 'large' : 'small'}
                      onClick={() => handleCopyOuterLink(item)}
                    >
                      <LinkOutlined sx={{ fontSize: 22 }} />
                    </IconButton>
                    <IconButton
                      size={isMobile ? 'large' : 'small'}
                      onClick={() =>
                        setExternalLinkData({
                          ...item
                        })
                      }
                    >
                      <SettingsOutlined sx={{ fontSize: 22 }} />
                    </IconButton>
                    <IconButton
                      size={isMobile ? 'large' : 'small'}
                      onClick={() => handleDeleteLink(item.Uid)}
                    >
                      <Close sx={{ fontSize: 22 }} />
                    </IconButton>
                    <Stack direction="row" spacing={1}>
                      {!!item?.Password && (
                        <LockOutlined
                          sx={{ color: 'text.disabled', fontSize: 22, ml: 1 }}
                        />
                      )}
                      {!!item.ExpirationDate && (
                        <Chip
                          size="small"
                          label={`до ${new Date(
                            item.ExpirationDate
                          ).toLocaleDateString()}`}
                        />
                      )}
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography>{accessTypeLabels[item.AccessType]}</Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <StyledButton onClick={onClose} sx={{ width: 'calc(50% - 20px)' }}>
            Отмена
          </StyledButton>
          <Button
            variant="contained"
            onClick={handleSuccess}
            sx={{ bgcolor: '#ff6a6a !important', width: 'calc(50% - 20px)' }}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <SearchUserDialog
        open={searchUserDialogOpen}
        excludeUserIds={[viewer.Id]}
        onSuccess={(data) => {
          if (users.find((x) => x.type === 0 && x.Id === data.Id)) {
            dispatch(
              showSnackbar({
                type: 'info',
                message: 'Данному пользователю уже установлены права'
              })
            );
            setSearchUserDialogOpen(false);
            return;
          }

          setUsers((state) => [
            ...state,
            ...(state.find((item) => item.Id === data.Id && item.type === 0)
              ? []
              : [{ ...data, type: 0 }]) //{ Id: data.Id, Name: data.Name, type: 0 }
          ]);
          setUsersAccessTypes((state) => [
            ...state,
            {
              userId: data.Id,
              value: selectOptions[0].value,
              type: 0
            }
          ]);
          setSearchUserDialogOpen(false);
        }}
        onClose={() => setSearchUserDialogOpen(false)}
      />
      {externalLinkData && (
        <ExternalLink
          data={externalLinkData}
          onSubmit={handleExternalLinkSubmit}
          onClose={() => setExternalLinkData(null)}
        />
      )}
    </>
  );
};

const StyledButton = styled((props) => (
  <Button
    variant="outlined"
    color="inherit"
    sx={{ color: 'text.primary', borderColor: 'divider' }}
    {...props}
  />
))({});
